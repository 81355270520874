import { withPlugins, eventBus, domEvents } from '@spon/plugins';

import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';

function Header({ node, name, plugins: { addEvents } }) {
	if (!node) return;

	const page = document.getElementById('page-wrapper');
	const background = node.querySelector('[data-background');
	const subNavAccs = [...node.querySelectorAll('.c-nav__subwrapper')];
	let activeNav = false;

	// Main Mobile Menu START
	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;

			document.body.style.position = 'fixed';
			// document.body.style.top = `${windowTop * -1}px`;
			page.style.transform = `translateY(${windowTop * -1}px)`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			// document.body.style.top = '';
			page.style.transform = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const nav = toggle({
		button: node.querySelector('.o-burger'),
		name,
		activeClass: 'is-active',
	});
	nav.init();

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'header' || target.closest('#header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('is-open');
		target.classList.add('is-open');

		eventBus.emit('menu:open');

		lock.capture();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('is-open');
		target.classList.remove('is-open');

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});

	eventBus.on('page:exited', nav.close);
	// Main Mobile Menu END

	subNavAccs.forEach(nav => {
		nav.addEventListener('show.bs.collapse', () => {
			activeNav = nav;
			background.style.height = `${node.offsetHeight + nav.scrollHeight}px`;
			page.style.opacity = 0.4;
			lock.capture();
		});

		nav.addEventListener('hide.bs.collapse', () => {
			if (nav === activeNav) {
				activeNav = false;
				background.style.height = 0;
				page.style.opacity = 1;
				lock.release();
			}
		});
	});

	// Headroom
	const options = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	};
	const headroom = new Headroom(node, options);
	headroom.init();
	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	function onKeyDown(event) {
		const { key } = event;
		if (nav.isOpen && key === 'Escape') {
			nav.close();
		}
	}

	function onClick({ target }) {
		if (target.id !== 'header' && !target.closest('#header')) {
			eventBus.emit('accordions:close', 'primary-navigation');
		}
	}

	addEvents({
		keydown: onKeyDown,
	});

	addEvents(document.body, {
		click: onClick,
	});
}

export default withPlugins(domEvents)(Header);
